$white: #FFFFFF;
$black: #000000;

$deep-purple: #252027;
$slate-blue: #7995A2;
$burnt-orange: #AB503C;
$pale-purple: #F4F4F5;
$orange: #F7931E;

$blue:    #375a7f !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #e74c3c !default;
$orange:  #fd7e14 !default;
$yellow:  #f39c12 !default;
$green:   #00bc8c !default;
$teal:    #20c997 !default;
$cyan:    #3498db !default;

$primary: #7B33F5;
$success: $green;
$danger: $red;
$warning: #F5EE58;
$info: $slate-blue;
$dark: $deep-purple;
$light: $pale-purple;

$theme-colors: (
  "accent-1": $primary,
  "accent-2": #46F57F
);

// Body

$body-bg:                   $deep-purple !default;
$body-color:                $pale-purple !default;
