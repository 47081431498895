$enable-gradients: true;

@import "./variables";
@import "node_modules/bootstrap/scss/bootstrap";


// OVERRIDES _______________________________________

$darker: theme-color-level(dark, 5);

@each $color, $value in $theme-colors {
    .swatch-#{$color} {
        color: color-yiq($value);
    }
}

.darker {
    background-color: $darker;
}

.darken {
    background-color: rgba($darker, .5);
}

.blur {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.display-4 {
    font-size: 2rem !important;
    @include media-breakpoint-up(sm) {
      font-size: 3.5rem !important;
    }
}

.lead {
    font-size: 1rem !important;
    @include media-breakpoint-up(sm) {
      font-size: 1.5rem !important;
    }
}

.h2 {
    font-size: 1rem !important;
    @include media-breakpoint-up(sm) {
      font-size: 2rem !important;
    }
}
