
@import "../../assets/styles/index.scss";

.logo {
  max-width: 100%;
  pointer-events: none;
  animation: none;

  @include media-breakpoint-up(sm) {
    max-width: 200px;
  }
  // @media (prefers-reduced-motion: no-preference) {
  //   animation: logo-spin infinite 20s linear;
  // }
}

.hero {
  // position: relative;
  background-image: linear-gradient(to bottom, rgba(black, .75), rgba(black, 0));
  padding-top: 120px;
  // top: -56px;
  // height: 100vh;
  margin-top: -56px;
  width: 100%;
  overflow: hidden;
}



@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
